/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div class="container">
        <main class="content">{children}</main>
      </div>
      <footer class="footer">
        <div class="content has-text-centered">
          <p>
            All rights reserved © {new Date().getFullYear()}, <a href="https://micrantha.com">Micrantha Software Solutions</a>
          </p>
        </div>
      </footer>
      <script async defer data-website-id="68a29ebf-0b7f-468b-8b4c-4c84692f7a60" src="https://analytics.micrantha.com/umami.js"></script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
