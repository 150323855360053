import React from "react"
import { Connector } from "mqtt-react"
import Layout from "../components/layout"
import MessageList from "../components/message-list"

function Monitor() {
  const pathway = process.env.REACT_APP_PATHWAY_URL || "wss://pathway.micrantha.com:8883"

  return (
    <Layout>
      <h2>Monitor Pathway</h2>
      <Connector mqttProps={{ pathway }}>
        <MessageList />
      </Connector>
    </Layout>
  )
}

export default Monitor
